import { Link } from "gatsby";
import React from "react";

import Layout from "../lib/components/Layout";
import PageTitlePanel from '../lib/components/PageTitlePanel';
import Storage from '../lib/storage';

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.about.metadata.title}
      description={Storage.pages.about.metadata.description}
      pageUrl={location.pathname}
      fixedButton="backToHome"
      bgNavSelector="titlePanel"
    >
      <PageTitlePanel
        title={Storage.pages.privacyPolicy.titlePanel.title}
        backgroundImg={Storage.pages.privacyPolicy.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <div className="relative w-full nexus-bg h-page-p v-page-p">
        <div className="flex flex-col items-start text-left gap-8">
          {/* <h1 className="font-bold nexus-text uppercase select-none">{Storage.pages.privacyPolicy.metadata.description}</h1> */}
          {/* <h1 className="font-bold nexus-text uppercase select-none">{Storage.pages.termsAndConditions.metadata.description}</h1> */}
          <p className="text-base nexus-text select-none">Your privacy is very important to us.</p>
          <p className="text-base nexus-text select-none">Accordingly, Nexus Commercial Services have developed this policy in order for you to understand how we collect, use, communicate and make use of personal information. The following outlines our privacy policy.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Overview</h1>
          <p className="text-base nexus-text select-none">When accessing the <Link to='/' className="font-bold hover:text-slate-600 dark:hover:text-slate-300">Nexus Commercial Services</Link> website, Nexus Commercial Services will learn certain information about you during your visit.</p>
          <p className="text-base nexus-text select-none">Like other commercial websites, our website utilizes a standard technology called "cookies" (see explanation below) and server logs to collect information about how our site is used. Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, time spent at our site, and the websites visited just before and just after our own, as well as your IP address.</p>

          {/* <ul className="nexus-text text-sm text-left list-disc grid list-inside gap-2 select-none">
            <li>Republish material from our website without prior written consent.</li>
            <li>Sell or rent material from our website.</li>
            <li>Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose.</li>
            <li>Redistribute any content from our website, including onto another website.</li>
          </ul> */}

          <h1 className="font-bold nexus-text uppercase select-none">Use of Cookies</h1>
          <p className="text-base nexus-text select-none">A cookie is a very small text document, which often includes an anonymous unique identifier. When you visit a website, that site's computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each website can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other sites.</p>

          <h1 className="font-bold nexus-text uppercase select-none">IP Addresses</h1>
          <p className="text-base nexus-text select-none">IP addresses are used by your computer every time you are connected to the Internet. Your IP address is a number that is used by computers on the network to identify your computer. IP addresses are automatically collected by our web server as part of demographic and profile data known as “traffic data” so that data (such as the Web pages you request) can be sent to you.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Email Information</h1>
          <p className="text-base nexus-text select-none">If you choose to correspond with us through email, we may retain the content of your email messages together with your email address and our responses. We provide the same protections for these electronic communications that we employ in the maintenance of information received online, mail and telephone. This also applies when you register for our website, sign up through any of our forms using your email address or make a purchase on this site. For further information see the email policies below.</p>

          <h1 className="font-bold nexus-text uppercase select-none">How Do We Use the Information That You Provide to Us?</h1>
          <p className="text-base nexus-text select-none">Broadly speaking, we use personal information for purposes of administering our business activities, providing customer service and making available other items and services to our customers and prospective customers.</p>
          <p className="text-base nexus-text select-none">Nexus Commercial Services will not obtain personally identifying information about you when you visit our site, unless you choose to provide such information to us, nor will such information be sold or otherwise transferred to unaffiliated third parties without the approval of the user at the time of collection. We may disclose information when legally compelled to do so, in other words, when we, in good faith, believe that the law requires it or for the protection of our legal rights.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Email Policies</h1>
          <p className="text-base nexus-text select-none">We are committed to keeping your e-mail address confidential. We do not sell, rent, or lease our subscription lists to third parties, and we will not provide your personal information to any third-party individual, government agency, or company at any time unless strictly compelled to do so by law.</p>
          <p className="text-base nexus-text select-none">We will use your e-mail address solely to provide timely information about Nexus Commercial Services. We will maintain the information you send via e-mail in accordance with applicable federal law.</p>

          <h2 className="font-bold nexus-text uppercase select-none">CAN-SPAM Compliance</h2>
          <p className="text-base nexus-text select-none">In compliance with the CAN-SPAM Act, all e-mail sent from our organization will clearly state who the e-mail is from and provide clear information on how to contact the sender. In addition, all e-mail messages will also contain concise information on how to remove yourself from our mailing list so that you receive no further e-mail communication from us.</p>

          <h2 className="font-bold nexus-text uppercase select-none">Choice/Opt-Out</h2>
          <p className="text-base nexus-text select-none">Our site provides users the opportunity to opt-out of receiving communications from us and our partners by reading the unsubscribe instructions located at the bottom of any e-mail they receive from us at any time.</p>
          <p className="text-base nexus-text select-none">Users who no longer wish to receive our newsletter or promotional materials may opt-out of receiving these communications by clicking on the unsubscribe link in the e-mail.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Use of External Links</h1>
          <p className="text-base nexus-text select-none"><Link to='/' className="font-bold hover:text-slate-600 dark:hover:text-slate-300">Nexus Commercial Services</Link> may contain links to many other websites. Nexus Commercial Services cannot guarantee the accuracy of information found at any linked site. Links to or from external sites not owned or controlled by Nexus Commercial Services does not constitute an endorsement by Nexus Commercial Services or any of its employees of the sponsors of these sites or the products or information presented therein. By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Policy Changes</h1>
          <p className="text-base nexus-text select-none">We reserve the right to amend this privacy policy at any time with or without notice. However, please be assured that if the privacy policy changes in the future, we will not use the personal information you have submitted to us under this privacy policy in a manner that is materially inconsistent with this privacy policy, without your prior consent.</p>
          <p className="text-base nexus-text select-none">We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained.</p>

        </div>
      </div>

    </Layout>
  )
}

export default Page
